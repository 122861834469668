import { App } from 'vue';
import { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
import {
  captureException,
  captureMessage,
  addBreadcrumb,
  withScope
} from '@sentry/browser';

export const sentryService = {
  init(app: App<Element>, router: Router) {
    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration()
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost', 'https://chocotravel-b2b-staging.dev.cloud.aviata.team', 'https://b2b.chocotravel.com'],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0
      
    });
  },
  captureException,
  captureMessage,
  addBreadcrumb,
  withScope
};
